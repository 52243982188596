import React from "react";
import PropTypes from "prop-types";

import { makeStyles } from "@material-ui/core/styles";
import { useTheme, useMediaQuery, Typography, Grid } from "@material-ui/core";

const Heading = (props) => {
  const useStyles = makeStyles((theme) => ({
    titleContainer: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      padding: "10px 0",
      width: "100%",
      [theme.breakpoints.down(theme.breakpoints.values[props.responsive])]: {
        marginBottom: "0",
      },
    },
    headingContainer: {
      width: "100%",
      marginBottom: theme.spacing(3),
      [theme.breakpoints.down(theme.breakpoints.values[props.responsive])]: {
        marginBottom: "0",
      },
    },
    children: {
      display: "flex",
      alignItems: "center",
      [theme.breakpoints.down(theme.breakpoints.values.desktop)]: {
        justifyContent: "space-between",
        flexWrap: "nowrap",
        flexDirection: "column",
        alignItems: "flex-start",
      },
    },
  }));
  const classes = useStyles();
  const theme = useTheme();
  const desktopUpScreen = useMediaQuery(theme.breakpoints.up("desktop"));

  return (
    <div className={classes.headingContainer}>
      <div className={classes.titleContainer}>
        <Typography variant="h3" color="textPrimary">
          {props.titleText}
        </Typography>
        {props.children && desktopUpScreen ? (
          <Grid className={classes.children}>{props.children}</Grid>
        ) : null}
      </div>
      {props.children && !desktopUpScreen ? (
        <Grid className={classes.children}>{props.children}</Grid>
      ) : null}
    </div>
  );
};

Heading.propTypes = {
  responsive: PropTypes.string,
  titleText: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
};

export default Heading;
