import React, { useState, useRef } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { useSelector, connect, useDispatch } from "react-redux";
import toNumber from "lodash/fp/toNumber";

import { makeStyles } from "@material-ui/core/styles";
import {
  Box,
  Button,
  Divider,
  Grid,
  IconButton,
  MenuItem,
  Paper,
  Select,
  TextField,
  Typography,
} from "@material-ui/core";
import LoadingSpinner from "../LoadingSpinner/LoadingSpinner";

import {
  ArrowForward as ArrowRightIcon,
  Cancel as DeleteIcon,
  Redeem as RedeemIcon,
  RemoveCircleOutline as RemoveCircleOutlineIcon,
} from "@material-ui/icons";
import { formatCurrency, formatQuantity } from "utils/helpers";
import { getSelectedProperty } from "../../selectors/selectedProperty";
import api from "utils/api";
import {
  calculateBookingTotal,
  calculateBookingTotalVat,
  calculateGiftCardRedemption,
} from "utils/bookingTotals";
import {
  removeRoomReservationFromBooking,
  removeParkingReservationFromBooking,
  changeServiceCountForRoomReservation,
  setGiftCard,
} from "features/booking/bookingSlice";
import { formatDateMedium } from "utils/timekeep";
import {
  trackRemoveReservationFromCartEvent,
  trackAddServiceToCartEvent,
  trackRemoveServiceFromCartEvent,
} from "utils/analytics";
import {
  selectCommonSearchParams,
  selectRoomReservations,
  selectParkingReservations,
} from "selectors/booking";

const useStyles = makeStyles((theme) => ({
  container: {
    padding: `${theme.spacing(2)}px ${theme.spacing(2)}px`,
    width: "400px",
    maxWidth: "100%",
    height: "fit-content",
    marginBottom: "20px",

    // Stick to top of the screen on scroll
    position: "sticky",
    top: "20px",
  },
  embedded: {
    padding: "0px",
    border: "none",
    width: "inherit",
    maxWidth: "inherit",
    marginBottom: 0,
  },
  bookingSummaryCard: {
    borderRadius: "5px",
    overflow: "hidden",
    width: "100%",
  },
  bookingSummaryTitle: {
    marginBottom: theme.spacing(1),
    textTransform: "uppercase",
  },
  arrowIcon: {
    color: theme.palette.text.secondary,
  },
  bookingSummaryCardPrimary: {
    color: theme.palette.text.primary,
  },
  bookingSummaryCardSecondary: {
    color: theme.palette.text.primary,
  },
  summaryItem: {
    padding: "1em 0px",
    borderBottom: `1px solid ${theme.palette.divider}`,
    width: "100%",
    alignItems: "center",
  },
  summaryItemAttribute: {
    flexGrow: 5,
  },
  editAvailBtn: {
    height: "40px",
  },
  giftCard: {
    border: theme.palette.other.lineDetail,
    borderRadius: "16px",
    padding: "0px 0px 0px 6px",
    marginLeft: "5px",
    minWidth: "0px", // needed to allow text to ellipsis on small screes
  },
  giftCardField: {
    marginRight: "10px",
    flexGrow: "1",
    "& .MuiOutlinedInput-root": {
      borderRadius: 20000,
      backgroundColor: theme.palette.background.default,
    },
  },
  removeButton: {
    color: theme.palette.primary.main,
  },
  giftCardPriceSummary: {
    paddingTop: theme.spacing(2),
  },
  giftCardSummaryDivider: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    backgroundColor: theme.palette.divider,
  },
  roomHeader: {
    marginBottom: "1rem",
    flexWrap: "nowrap",
  },
  right: {
    textAlign: "right",
  },
  stepper: {
    marginLeft: "5px",
    marginRight: "5px",
    width: "auto",
  },
  serviceListItem: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
  },
  dropdown: {
    borderRadius: "20px",
    marginRight: theme.spacing(1),
    backgroundColor: theme.palette.background.componentBackground1,
    "&.Mui-focused": {
      backgroundColor: theme.palette.background.componentBackground1,
    },
    "& .MuiOutlinedInput-input": {
      backgroundColor: theme.palette.background.componentBackground1,
    },
  },
  dropdownRoot: {
    ...theme.typography.body1,
    backgroundColor: theme.palette.background.componentBackground1,
    padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`,
  },
  dropdownIcon: {
    color: theme.palette.text.red,
  },
  menuItem: {
    backgroundColor: theme.palette.background.componentBackground1,
    "&:hover": {
      backgroundColor: theme.palette.background.componentBackground1,
    },
    "&.Mui-selected": {
      backgroundColor: theme.palette.background.componentBackground1,
    },
  },
  serviceName: {
    marginLeft: theme.spacing(1),
  },
}));

const Basket = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const { arrival, departure } = useSelector(selectCommonSearchParams);

  const roomReservations = useSelector(selectRoomReservations);
  const parkingReservations = useSelector(selectParkingReservations);

  const [loadingGiftCard, setLoadingGiftCard] = useState(false);
  const giftCardFieldRef = useRef();

  const guestsStringForReservation = (roomIndex) => {
    const reservation = props.roomReservations[roomIndex];
    const adults = reservation.adults;
    const children = (reservation.childrenAges || []).length;

    const adultsStr = formatQuantity(adults, "Adult");
    let childrenStr = null;

    if (children === 1) {
      childrenStr = "1 Child";
    } else if (children >= 1) {
      childrenStr = `${children} Children`;
    }

    return [adultsStr, childrenStr].filter((s) => s).join(", ");
  };

  const removeRoomReservation = (e) => {
    const deletedReservation = roomReservations[e.currentTarget.value];
    trackRemoveReservationFromCartEvent(deletedReservation);

    dispatch(removeRoomReservationFromBooking(toNumber(e.currentTarget.value)));

    if (props.onReservationRemoved) {
      props.onReservationRemoved();
    }
  };

  const removeParkingReservation = (e) => {
    const deletedReservation = parkingReservations[e.currentTarget.value];
    trackRemoveReservationFromCartEvent(deletedReservation);

    dispatch(removeParkingReservationFromBooking(toNumber(e.currentTarget.value)));

    if (props.onReservationRemoved) {
      props.onReservationRemoved();
    }
  };

  const serviceCountChanged = (reservationIdx, serviceId, serviceDate, newCount) => {
    const oldService = Object.assign(
      {},
      roomReservations[reservationIdx].services.find((s) => s.service.id === serviceId)
    );

    dispatch(
      changeServiceCountForRoomReservation({
        reservationIdx,
        serviceId,
        count: newCount,
        date: serviceDate,
      })
    );

    if (oldService) {
      if (oldService.count > newCount) {
        trackRemoveServiceFromCartEvent(oldService, oldService.count - newCount);
      } else {
        trackAddServiceToCartEvent(oldService, newCount - oldService.count);
      }
    }

    if (newCount === 0 && props.onServiceRemoved) {
      props.onServiceRemoved(serviceId);
    }
  };

  const applyGiftCard = async (e) => {
    const giftCardNumber = giftCardFieldRef.current.value;

    // Dont do anything if no text
    if (!giftCardNumber) {
      return;
    }

    // Check gift card balance
    setLoadingGiftCard(true);

    try {
      const roomRateCodes = roomReservations.map((r) => r.ratePlan.code);
      const response = await api.getGiftCardBalance(giftCardNumber, roomRateCodes);

      dispatch(setGiftCard(response.data));

      props.onNotifOpen("Gift card added");
    } catch (e) {
      console.error(e.message);

      if (e.response.data && e.response.data.error) {
        props.onNotifOpen(e.response.data.error, { variant: "error" });
      } else {
        props.onNotifOpen(e.message, { variant: "error" });
      }
    } finally {
      setLoadingGiftCard(false);
    }
  };

  const quantityDropdownForService = (service, reservationIdx) => {
    const values = Array.from(Array(service.maxCount + 1).keys());

    return (
      <Select
        value={service.count}
        onChange={(e) => {
          serviceCountChanged(reservationIdx, service.service.id, service.date, e.target.value);
        }}
        className={classes.dropdown}
        variant="outlined"
        classes={{
          root: classes.dropdownRoot,
          icon: classes.dropdownIcon,
        }}
        MenuProps={{
          classes: {
            list: classes.menuItem,
          },
        }}
        autoWidth={true}>
        {values.map((val) => (
          <MenuItem value={val} key={val}>
            {val}
          </MenuItem>
        ))}
      </Select>
    );
  };

  const giftCardField = () => {
    return (
      <Grid className={classes.summaryItem}>
        <Typography variant="subtitle1" gutterBottom>
          Gift card
        </Typography>
        {props.giftCard ? (
          <Grid container wrap="nowrap" alignItems="center">
            <RedeemIcon />
            <Grid container wrap="nowrap" alignItems="center" className={classes.giftCard}>
              <Typography variant="body1">{props.giftCard.card_reference}</Typography>
              <IconButton
                aria-label="Remove gift card"
                component="span"
                size="small"
                disableRipple
                className={classes.removeButton}
                onClick={() => dispatch(setGiftCard(null))}>
                <DeleteIcon />
              </IconButton>
            </Grid>
          </Grid>
        ) : (
          <Grid container direction="row" wrap="nowrap" alignItems="center">
            <TextField
              inputRef={giftCardFieldRef}
              type="text"
              size="small"
              variant="outlined"
              name="giftcard"
              className={classes.giftCardField}
              placeholder="Enter card number"
              margin="none"
            />
            {loadingGiftCard ? (
              <LoadingSpinner loading={loadingGiftCard} size={20} />
            ) : (
              <Button
                variant="contained"
                type="submit"
                color="primary"
                size="small"
                onClick={applyGiftCard}>
                Apply
              </Button>
            )}
          </Grid>
        )}
      </Grid>
    );
  };

  return (
    <Paper
      className={classNames(
        props.className,
        classes.container,
        props.embedded && classes.embedded
      )}>
      <Grid>
        <Box
          className={classNames(
            classes.bookingSummaryCard,
            props.color === "primary"
              ? classes.bookingSummaryCardPrimary
              : classes.bookingSummaryCardSecondary
          )}>
          <Grid container className={classes.bookingSummary} direction="column">
            {!props.embedded && (
              <Typography
                variant="overline"
                color="textSecondary"
                className={classes.bookingSummaryTitle}>
                Booking Summary
              </Typography>
            )}
            <Grid container justifyContent="flex-start" alignItems="center">
              <Typography variant="h5">{props.selectedProperty.name}</Typography>
            </Grid>
            <Grid container className={classes.summaryItem} justifyContent="space-between">
              <Grid item>
                <Typography variant="body1">Check in</Typography>
                <Typography variant="subtitle1">{formatDateMedium(arrival)}</Typography>
              </Grid>
              <Grid item>
                <ArrowRightIcon className={classes.arrowIcon} />
              </Grid>
              <Grid item className={classes.right}>
                <Typography variant="body1">Check out</Typography>
                <Typography variant="subtitle1">{formatDateMedium(departure)}</Typography>
              </Grid>
            </Grid>
            {roomReservations.map((reservation, reservationIdx) => (
              <Grid key={`reservation-${reservationIdx}`} container className={classes.summaryItem}>
                <Grid
                  container
                  justifyContent="space-between"
                  alignItems="center"
                  className={classes.roomHeader}>
                  <Typography variant="h6">{`Room ${reservationIdx + 1}`}</Typography>
                  {props.canRemoveReservations ? (
                    <IconButton
                      value={reservationIdx}
                      onClick={removeRoomReservation}
                      color="primary"
                      size="small">
                      <RemoveCircleOutlineIcon className={classes.removeButton} />
                    </IconButton>
                  ) : null}
                </Grid>
                <Grid container>
                  <Grid item className={classes.summaryItemAttribute}>
                    <Grid container justifyContent="space-between" wrap="nowrap" spacing={2}>
                      <Grid item>
                        <Typography variant="body1">
                          {`${formatQuantity(reservation.timeSlices.length, "night")}, ${
                            reservation.unitGroup.name
                          }, ${guestsStringForReservation(reservationIdx)}`}
                        </Typography>
                        {reservation.ratePlan?.description && (
                          <Typography variant="body1">
                            {reservation.ratePlan.description}
                          </Typography>
                        )}
                      </Grid>
                      <Grid item>
                        <Typography variant="subtitle2">
                          {formatCurrency(
                            reservation.totalGrossAmount.currency,
                            reservation.totalGrossAmount.amount
                          )}
                        </Typography>
                      </Grid>
                    </Grid>
                    {reservation.services.length > 0 && (
                      <Box mt={2} mb={1}>
                        <Typography variant="subtitle1">Customise stay</Typography>
                      </Box>
                    )}
                    {reservation.services.map((service, serviceIdx) => (
                      <Grid
                        container
                        justifyContent="space-between"
                        wrap="nowrap"
                        alignItems="center"
                        key={`service-${reservationIdx}-${serviceIdx}`}
                        className={classes.serviceListItem}>
                        <Grid container wrap="nowrap" alignItems="center">
                          {quantityDropdownForService(service, reservationIdx)}

                          <Grid>
                            <Typography className={classes.serviceName} variant="body1">
                              {service.cmsTitle || service.service.name}
                            </Typography>
                            {service.additionalInfo && (
                              <Typography
                                className={classes.serviceName}
                                variant="body2"
                                color="textSecondary">
                                {service.additionalInfo}
                              </Typography>
                            )}
                          </Grid>
                        </Grid>
                        <Typography variant="subtitle1">
                          {formatCurrency(
                            service.totalAmount.currency,
                            service.totalAmount.grossAmount
                          )}
                        </Typography>
                      </Grid>
                    ))}
                  </Grid>
                </Grid>
              </Grid>
            ))}

            {parkingReservations.map((reservation, reservationIdx) => (
              <Grid
                key={`parking-reservation-${reservationIdx}`}
                container
                className={classes.summaryItem}>
                <Grid
                  container
                  justifyContent="space-between"
                  alignItems="center"
                  className={classes.roomHeader}>
                  <Typography variant="subtitle1">
                    {`Parking Reservation ${reservationIdx + 1}`}
                  </Typography>
                  {props.canRemoveReservations ? (
                    <IconButton
                      value={reservationIdx}
                      onClick={removeParkingReservation}
                      color="primary"
                      size="small">
                      <RemoveCircleOutlineIcon />
                    </IconButton>
                  ) : null}
                </Grid>
                <Grid container>
                  <Grid item className={classes.summaryItemAttribute}>
                    <Grid container justifyContent="space-between">
                      <Typography variant="body1">1 space</Typography>
                      <Typography variant="subtitle2">
                        {formatCurrency(
                          reservation.totalGrossAmount.currency,
                          reservation.totalGrossAmount.amount
                        )}
                      </Typography>
                    </Grid>
                    <Box marginY={1}>
                      <Grid>
                        <Typography variant="body1">{reservation.unitGroup.name}</Typography>
                      </Grid>
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
            ))}

            {props.showGiftCard ? giftCardField() : null}

            <Grid container className={classes.giftCardPriceSummary} direction="column">
              <Grid container justifyContent="space-between">
                <Grid item>
                  <Typography variant="body1">Booking cost</Typography>
                </Grid>
                <Typography variant="subtitle1">
                  {formatCurrency("GBP", props.bookingTotal)}
                </Typography>
              </Grid>

              {props.giftCard ? (
                <Grid container justifyContent="space-between">
                  <Grid item>
                    <Grid>
                      <Typography variant="body1">Gift card</Typography>
                    </Grid>
                  </Grid>
                  <Typography variant="body1">
                    {formatCurrency("GBP", -1 * props.giftCardValue)}
                  </Typography>
                </Grid>
              ) : null}

              <Divider className={classes.giftCardSummaryDivider} />
            </Grid>

            <Grid container justifyContent="space-between">
              <Grid item>
                <Typography variant="h6">Total to pay</Typography>
              </Grid>
              <Typography variant="h4">
                {formatCurrency("GBP", props.bookingTotal - props.giftCardValue)}
              </Typography>
            </Grid>
            {props.bookingTotalVat > 0 ? (
              <div>
                <Typography variant="body2" color="textSecondary" style={{ float: "right" }}>
                  Including VAT of {formatCurrency("GBP", props.bookingTotalVat)}
                </Typography>
              </div>
            ) : null}
          </Grid>
        </Box>
      </Grid>
    </Paper>
  );
};

Basket.defaultProps = {
  color: "primary",
};

Basket.propTypes = {
  className: PropTypes.string,
  embedded: PropTypes.bool,
  roomReservations: PropTypes.array,
  parkingReservations: PropTypes.array,
  giftCard: PropTypes.object,
  selectedProperty: PropTypes.object,
  bookingTotal: PropTypes.number,
  bookingTotalVat: PropTypes.number,
  giftCardValue: PropTypes.number,
  canRemoveReservations: PropTypes.bool,
  onReservationRemoved: PropTypes.func,
  onServiceRemoved: PropTypes.func,
  showGiftCard: PropTypes.bool,
  onNotifOpen: PropTypes.func,
  color: PropTypes.oneOf(["primary", "secondary"]),
};

const mapStateToProps = (state) => {
  return {
    roomReservations: state.booking.roomReservations,
    parkingReservations: state.booking.parkingReservations,
    giftCard: state.booking.giftCard,
    selectedProperty: getSelectedProperty(state),
    bookingTotal: calculateBookingTotal([
      ...state.booking.roomReservations,
      ...state.booking.parkingReservations,
    ]),
    bookingTotalVat: calculateBookingTotalVat([
      ...state.booking.roomReservations,
      ...state.booking.parkingReservations,
    ]),
    giftCardValue: calculateGiftCardRedemption(
      [...state.booking.roomReservations, ...state.booking.parkingReservations],
      state.booking.giftCard
    ),
  };
};

const ConnectedBasket = connect(mapStateToProps)(Basket);
export default ConnectedBasket;
