import React from "react";
import PropTypes from "prop-types";
import {
  Button,
  Grid,
  Paper,
  Typography,
  makeStyles,
  useTheme,
  useMediaQuery,
} from "@material-ui/core";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import { AvailableUnit } from "../AvailableUnit/AvailableUnit";
import LowestPrice from "components/LowestPrice";
import ImageCarousel from "components/ImageCarousel";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  card: {
    padding: theme.spacing(2),
    margin: "auto",
    color: theme.palette.text.primary,
    backgroundColor: theme.palette.background.componentBackground1,
  },
  cardContent: {
    flexWrap: "nowrap",
    flexDirection: "column",
    [theme.breakpoints.up("desktop")]: {
      flexDirection: "row",
    },
  },
  imgContainer: {
    height: "auto",
    position: "relative",
    [theme.breakpoints.up("desktop")]: {
      width: "70%",
    },
  },
  titleRateRow: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: theme.spacing(1),

    [theme.breakpoints.up(theme.breakpoints.values.tablet)]: {
      marginBottom: theme.spacing(2),
    },
  },
  roomDescription: {
    overflow: "hidden",
    textOverflow: "ellipsis",
    display: "-webkit-box",
    "-webkit-line-clamp": "3",
    "-webkit-box-orient": "vertical",
  },
  actionsRow: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(3),
  },
  moreDetails: {
    padding: "4px 0px",
    color: theme.palette.text.red,
    "&:hover": { backgroundColor: "inherit" },
  },
}));

const RoomCard = (props) => {
  const classes = useStyles();
  const theme = useTheme();
  const tabletUpScreen = useMediaQuery(theme.breakpoints.up("tablet"));

  return (
    <div className={classes.root}>
      <Paper className={classes.card}>
        <Grid container className={classes.titleRateRow}>
          <Typography variant="h5">{props.name}</Typography>
          <LowestPrice
            availabilities={props.unitGroupAvailabilities}
            fromVariant={tabletUpScreen ? "body1" : "caption"}
            priceVariant="subtitle1"
            stayNights={props.stayNights}
            onDark={false}
          />
        </Grid>
        <Grid container spacing={2} className={classes.cardContent}>
          {props.gallery ? (
            <Grid item className={classes.imgContainer}>
              <ImageCarousel
                images={props.gallery.map((g) => g.image).filter((i) => !!i)}
                enableAutoScroll={true}
              />
            </Grid>
          ) : null}
          <Grid item xs={12}>
            <Grid container direction="column">
              <Grid item>
                <Typography variant="body1" className={classes.roomDescription} gutterBottom>
                  {props.children}
                </Typography>
              </Grid>
              {props.selectable ? (
                <Grid item className={classes.actionsRow}>
                  <Button
                    className={classes.moreDetails}
                    onClick={() => props.onShowRoomDetailsClick(props.unitGroupCode)}
                    endIcon={<ChevronRightIcon />}>
                    More details
                  </Button>
                </Grid>
              ) : null}
              {props.unitGroupAvailabilities.map((availability, index) => (
                <AvailableUnit
                  key={index}
                  availability={availability}
                  onReservationSelect={props.onReservationSelect}
                  stayNights={props.stayNights}
                  verticalMode={!tabletUpScreen}
                />
              ))}
            </Grid>
          </Grid>
        </Grid>
      </Paper>
    </div>
  );
};

RoomCard.defaultProps = {
  index: 0,
};

RoomCard.propTypes = {
  name: PropTypes.string,
  unitGroupAvailabilities: PropTypes.arrayOf(PropTypes.object),
  onReservationSelect: PropTypes.func,
  onShowRoomDetailsClick: PropTypes.func,
  imageUrl: PropTypes.string,
  gallery: PropTypes.array,
  unitGroupCode: PropTypes.string,
  stayNights: PropTypes.number,
  selectable: PropTypes.bool,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
  index: PropTypes.number, // used for varying colored background
};

export default RoomCard;
