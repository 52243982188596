import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { formatCurrency } from "utils/helpers";

const useStyles = makeStyles((theme) => ({
  priceContainer: {
    height: "max-content",
    color: theme.palette.text.secondary,
  },
  lowestPrice: {
    color: theme.palette.text.red,
    fontWeight: 700,
  },
  priceContainerDark: {
    color: theme.palette.text.onDark.secondary,
  },
}));

const LowestPrice = (props) => {
  const classes = useStyles();

  const startingRoomPrice = (availabilities) => {
    const lowestAvailability = availabilities.reduce((min, availability) => {
      return min.totalGrossAmount.amount < availability.totalGrossAmount.amount
        ? min
        : availability;
    });

    const pricePerNight = lowestAvailability.totalGrossAmount.amount / (props.stayNights || 1);

    return formatCurrency(lowestAvailability.totalGrossAmount.currency, pricePerNight, false);
  };

  return (
    <Typography
      variant={props.fromVariant}
      className={clsx(classes.priceContainer, {
        [classes.priceContainerDark]: props.onDark,
      })}>
      From&nbsp;
      <Typography variant={props.priceVariant} className={classes.lowestPrice} component="span">
        {startingRoomPrice(props.availabilities)} per night
      </Typography>
    </Typography>
  );
};

LowestPrice.defaultProps = {
  fromVariant: "h6",
  priceVariant: "h5",
  onDark: true,
};

LowestPrice.propTypes = {
  availabilities: PropTypes.arrayOf(PropTypes.object),
  fromVariant: PropTypes.string,
  priceVariant: PropTypes.string,
  stayNights: PropTypes.number,
  onDark: PropTypes.bool,
};

export default LowestPrice;
