import React from "react";
import PropTypes from "prop-types";
import { Divider, Grid, Paper, Typography, makeStyles } from "@material-ui/core";

import { ADDON_TYPE_REGULAR, ADDON_TYPE_BASKET, ADDON_TYPE_DATE } from "utils/constants";

import ImageCarousel from "components/ImageCarousel";

// Child elements
import Header from "./components/Header";
import MoreInfo from "./components/MoreInfo";

import RegularAddonDetails from "./components/RegularAddonDetails";
import BasketAddonDetails from "./components/BasketAddonDetails";
import DateAddonDetails from "./components/DateAddonDetails";

const useStyles = makeStyles((theme) => ({
  card: {
    position: "relative",
    width: "100%",
    color: theme.palette.text.primary,
    backgroundColor: theme.palette.background.componentBackground1,
  },
  body: {
    padding: theme.spacing(2),

    [theme.breakpoints.up(theme.breakpoints.values.tablet)]: {
      padding: theme.spacing(3),
    },
  },
  detailsContainer: {
    flexDirection: "column",

    [theme.breakpoints.up(theme.breakpoints.values.tablet)]: {
      flexDirection: "row",
      flexWrap: "nowrap",
    },
  },
  divider: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  imgContainer: {
    height: "auto",
    position: "relative",
    marginBottom: theme.spacing(2),

    [theme.breakpoints.up(theme.breakpoints.values.tablet)]: {
      width: "70%",
      marginRight: theme.spacing(3),
      marginBottom: 0,
    },
  },
}));

const AddOnCard = (props) => {
  const classes = useStyles();

  /*
    Details
      - There are 3 modes for services. Which one to show is driven by the CMS
      - Regular (e.g Early check-in, Breakfast)
        These addons can be sold by person, adult/child, or by room
        They can contain one or more pms services and can be added to the basket

      - Basket (e.g cocktails)
        These addons contain many options like a basket. Each option can have
        one or more options which point to an underlying pms service.
        Options can be categorized.

        Basket addons can be sold by person (with quantity selectors), or by roon.
        When sold by room, the addon can only be added once.

      - Daily (e.g bike hire)
        These addons allow a user to select a service by date.
        Only one pms service can be added to a daily addon, and the
        services must be sold by 'entire stay' (ie no arrival/departure only services)
        They can support both person (quantity selector) and room (single selection) services
  */
  const newAddonDetailsView = () => {
    switch (props.addon?.addon_details?.__component) {
      case ADDON_TYPE_REGULAR:
        return (
          <RegularAddonDetails
            serviceCountInBasket={props.serviceCountInBasket}
            pmsServices={props.addon?.pmsServices}
            addonDetails={props.addon?.addon_details}
            onAddServices={props.onAddServices}
            onRemoveServices={props.onRemoveServices}
            onUpdateServiceCount={props.onUpdateServiceCount}
          />
        );
      case ADDON_TYPE_BASKET:
        return (
          <BasketAddonDetails
            serviceCountInBasket={props.serviceCountInBasket}
            pmsServices={props.addon?.pmsServices}
            addonDetails={props.addon?.addon_details}
            onAddServices={props.onAddServices}
            onRemoveServices={props.onRemoveServices}
            onUpdateServiceCount={props.onUpdateServiceCount}
          />
        );
      case ADDON_TYPE_DATE:
        return (
          <DateAddonDetails
            checkInDate={props.checkInDate}
            checkOutDate={props.checkOutDate}
            serviceCountInBasket={props.serviceCountInBasket}
            pmsServices={props.addon?.pmsServices}
            addonDetails={props.addon?.addon_details}
            onAddServices={props.onAddServices}
            onRemoveServices={props.onRemoveServices}
            onUpdateServiceCount={props.onUpdateServiceCount}
          />
        );
      default:
        return null;
    }
  };

  // Will show the section if there are links or more info to show
  const hasMoreInfo = props.addon?.more_info || props.addon?.links?.length > 0;

  return (
    <Grid container className={props.className}>
      <Paper className={classes.card}>
        {/* Header */}
        <Header
          icon={props.addon?.icon}
          title={props.addon?.title}
          services={props.addon?.pmsServices || []}
        />

        {/* Body */}
        <div className={classes.body}>
          <Grid container className={classes.detailsContainer}>
            {props.addon?.images?.length > 0 && (
              <div className={classes.imgContainer}>
                <ImageCarousel images={props.addon?.images} enableAutoScroll />
              </div>
            )}
            <Grid container direction="column">
              <div>
                <Typography variant="body1">{props.addon?.description}</Typography>
                <Divider className={classes.divider} />
              </div>
              <div style={{ flexGrow: 1 }}>{newAddonDetailsView()}</div>
            </Grid>
          </Grid>
        </div>

        {/* More info */}
        {hasMoreInfo && (
          <MoreInfo
            links={props.addon.links}
            description={props.addon.more_info}
            onOpenMarkdownModel={props.onOpenMarkdownModel}
          />
        )}
      </Paper>
    </Grid>
  );
};

AddOnCard.defaultProps = {
  index: 0,
};

AddOnCard.propTypes = {
  className: PropTypes.string,
  checkInDate: PropTypes.string,
  checkOutDate: PropTypes.string,
  serviceCountInBasket: PropTypes.func,
  onAddServices: PropTypes.func,
  onRemoveServices: PropTypes.func,
  onUpdateServiceCount: PropTypes.func,
  onOpenMarkdownModel: PropTypes.func,
  // Addon cms metadata
  addon: PropTypes.shape({
    icon: PropTypes.string,
    title: PropTypes.string,
    description: PropTypes.string,
    images: PropTypes.arrayOf(PropTypes.object),
    links: PropTypes.arrayOf(PropTypes.object),
    more_info: PropTypes.string,
    pmsServices: PropTypes.arrayOf(PropTypes.object),
    addon_details: PropTypes.shape({
      // Full shape will be defined in the underlying addon detail types
      __component: PropTypes.oneOf([ADDON_TYPE_REGULAR, ADDON_TYPE_BASKET, ADDON_TYPE_DATE]),
    }),
  }),
  index: PropTypes.number,
};

export default AddOnCard;
